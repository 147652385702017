import { createRouter, createWebHistory } from "vue-router";
import { getUserInfo } from '@/api/index'
import { GetTenantInfo } from '@/utils/tenant'

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      meta: { title: "前端微应用" },
      redirect: "/main",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../view/login/login.vue"),
    },
    {
      path: "/adminLogin",
      name: "adminLogin",
      component: () => import("@/view/adminLogin"),
    },
    {
      path: "/tenants",
      name: "tenants",
      component: () => import("@/view/main/tenants"),
    },
    {
      path: "/producth5",
      name: "producth5",
      component: () => import("../view/maintenance/ProductH5.vue"),
    },
    {
      path: "/main",
      name: "main",
      component: () => import("../view/main/main_new2.vue"),
      redirect: '/main',
      children: [
        {
          path: '/main',
          component: () => import("../view/main/Home/index.vue"),
        },
        {
          path: '/OverviewStatus',
          component: () => import("../view/maintenance/OverviewStatus.vue"),
        },
        {
          path: '/StorageMonitoring',
          component: () => import("../view/maintenance/StorageMonitoring.vue"),
        },
        {
          path: '/trafficMonitoring',
          component: () => import("../view/maintenance/trafficMonitoring.vue"),
        },
        {
          path: '/Operation',
          component: () => import("../view/maintenance/Operation.vue"),
        },
        {
          path: '/ProductQrcode',
          component: () => import("../view/maintenance/ProductQrcode.vue"),
        },
      ]
    },
    {
      path: "/lodmain",
      name: "lodmain",
      component: () => import("../view/main/main.vue"),
    },
    {
      path: "/userInfo",
      name: "userInfo",
      component: () => import("../view/profile/password.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.query.from_out_token) {
		localStorage.setItem('maToken', `"${to.query.from_out_token}"`)
		const res = await getUserInfo()
		localStorage.setItem('userInfo', JSON.stringify(res))
		await GetTenantInfo(res)

		let q = {
			...to.query
		}
		delete q.from_out_token
		let queryString = Object.keys(q)
			.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(q[key])}`)
			.join('&');

		window.location.href = `${to.path}${queryString ? '?' + queryString : ''}`;
		return;
	}
  const maToken = localStorage.getItem("maToken");
  if (!localStorage.getItem("userInfo")) {
    localStorage.setItem("userInfo", "{}");
  }
  const userInfo = localStorage.getItem("userInfo");
  if (maToken && userInfo !== "{}") {
    if (to.path === "/login" || to.path === "/adminLogin") {
      localStorage.setItem("maToken", "");
      localStorage.setItem("userInfo", "{}");
    }
    next();
  } else {
    if (
      to.path === "/login" ||
      to.path === "/producth5" ||  //产品二维码
      to.path === "/adminLogin" ||
      to.path === "/superv/engineering/h5" || //监理签章
      to.path === "/attends/sign"|| //借阅签名
      to.path === "/attends/register" //简历登记
    ) {
      next();
    } else {
      console.log("to.path", to.path);
      window.location.href = "/login";
    }
  }
});

export default router;
