import axios from 'axios'
const url = process.env.VUE_APP_URL_BASE + "account/";
import nifty from 'nifty-util'

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();

  // 请求拦截
  service.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 响应拦截
  service.interceptors.response.use(
    (response) => {
      const { status, data } = response;
      return data || void 0;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return service;
}

const request = createService();

/**
 * GET请求
 * @param api
 * @returns
 */
export function get(api, headers = {}) {
  return function (params) {
    return request({
      url: generateParams(url + api, params),
      method: "GET",
      headers: generateHeaders(headers),
    });
  };
}

/**
 * POST请求
 * @param api
 * @returns
 */
export function post(api) {
  return function (body) {
    return function (params) {
      return request({
        url: generateParams(url + api, params),
        method: "POST",
        headers: generateHeaders(),
        data: body,
      });
    };
  };
}

/**
 * PATCH请求
 * @param api
 * @returns
 */
export function patch(api) {
  return function (body) {
    return function (params) {
      return request({
        url: generateParams(url + api, params),
        method: "PATCH",
        headers: generateHeaders(),
        data: body,
      });
    };
  };
}

/**
 * DELETE请求
 * @param api
 * @returns
 */
export function del(api) {
  return function (params) {
    return request({
      url: generateParams(url + api, params),
      method: "DELETE",
      headers: generateHeaders(),
    });
  };
}

/**
 * 构建URL参数
 * @param url
 * @param params
 * @returns
 */
function generateParams(url, params) {
  const newUrl = new URL(url);
  let finalUrl;

  if(typeof params === 'object') {
    for(const [key, value] of Object.entries(getQuery(params))) {
      newUrl.searchParams.append(key, value)
    }

    finalUrl = newUrl.toString();
  } else if (typeof params === "string") {
    finalUrl = url.endsWith("/") ? url + params : `${url}/${params}`;
  } else {
    finalUrl = newUrl.toString();
  }

  return finalUrl;
}

/**
 * 构建请求头
 * @returns
 */
function generateHeaders(customHeaders = {}) {
  const token = localStorage.getItem("maToken")?.replace(/^"(.*)"$/, '$1');

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    ...customHeaders,
  };

  if (token) headers.Authorization = `Bearer ${ token }`;

  return headers
}


function getQuery(query) {
	const cloneQuery = nifty.deepClone(query)

	for(let key in cloneQuery) {
		const value = cloneQuery[key]

		if(!nifty.isYummy(value)) {
			delete cloneQuery[key]
		}
	}

	if(nifty.isYummy(cloneQuery)) {
		return cloneQuery
	}

	return {}
}
