import axios from "axios";
import { ElMessage } from "element-plus";
const baseURL = process.env.VUE_APP_URL_BASE;

console.log("process.env==11", process.env);
const service = axios.create({
  baseURL,
  timeout: 80000,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});

service.interceptors.request.use(
  (config) => {
    // console.log(localStorage.getItem('maToken'))
    const str = localStorage.getItem("maToken");
    let Authorization = "";
    if (str)
      Authorization = `Bearer ${localStorage
        .getItem("maToken")
        .replace('"', "")
        .replace('"', "")}`;
    config.headers.Authorization = Authorization;
    // 追加时间戳，防止GET请求缓存
    if (config.method?.toUpperCase() === "GET") {
      config.params = { ...config.params, t: new Date().getTime() };
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  (response) => {
    // return Promise.resolve(response.data);
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText || "Error"));
    }
    const res = response.data;
    // 响应成功
    if (res.code === 0) {
      if('result' in res){
        return Promise.resolve(res.result);
      }else{
        return Promise.resolve(res);
      }
    } else if (response.code === 401) {
      // 没有权限，如：未登录、登录过期等，需要跳转到登录页
      localStorage.setItem("userInfo", "{}");
      localStorage.setItem("maToken", "");
      window.location.href = "/login";
    } else if (res.code === 10403) {
      // 账号或密码错误
      ElMessage.error(res.message.msg);
      return Promise.resolve(res.result);
    } else {
      ElMessage.error(res.message);
      return Promise.reject(res);
    }
  },
  (err) => {
    if (err.message.includes('timeout')) {
			ElMessage.error('当前网络不稳定，请关闭刷新重新打开')
		} else {
      if (err.response.status === 401) {
        localStorage.setItem("userInfo", "{}");
        localStorage.setItem("maToken", "");
        window.location.href = "/login";
      } else {
        ElMessage.error(err.message);
      }
    }
  }
);

export default service;
