import { get, post } from './request'
import localforage from 'localforage'

export default class TenantsSerive {
  static instance

  static getInstance() {
    if(!TenantsSerive.instance) {
      TenantsSerive.instance = new TenantsSerive
    }

    return TenantsSerive.instance
  }

  // 获取租户列表
  async list(query) {
    try {
      return await get('api/user/getTenantList')(query)
    } 
    catch (error) {
      return error  
    }
  }

  // 选择租户
  async selectTenant(body) {
    try {
      const data = {
        tenant_id: body.tenantId
      }

      return await post('api/user/superUserSelect')(data)()
    } 
    catch (error) {
      return error
    }
  }

  // 获取当前租户信息
  async getTenantInfo() {
    try {
      const result = await get('api/user/tenant')(data)

      localStorage.setItem('tenantInfo', JSON.stringify(result.result))
      localforage.setItem('tenantInfo', result.result)
      
      return result
    } 
    catch (error) {
      return error  
    }
  }
   // 获取当前租户信息--新接口
  async getTenantAllInfo() {
    try {
      const result = await get('api/user/tenant')()

      let currentIndex = result.result?.list.findIndex(item => item.current == true)
      if (currentIndex == -1) return
      localStorage.setItem('tenantInfo', JSON.stringify(result.result?.list[currentIndex]))
      localforage.setItem('tenantInfo', result.result?.list[currentIndex])
      
      return result
    } 
    catch (error) {
      return error  
    }
  }

  // 获取租户所属公司LOGO
  async getLogo(uuid) {
    console.log({ uuid })
    try {
      return await get('api/attachment/getAttachment')({ uuid })
    } 
    catch (error) {
      return error  
    }
  }
}
