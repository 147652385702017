<template>
  <div class="scrollbar-enabled">
    <router-view v-if="state.showRouter"></router-view>
    <div class="base-warp"></div>
  </div>
</template>
<script setup>
import { nextTick, onMounted, provide, reactive, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import localforage from 'localforage'
import { loadScript, loadmClient } from './utils/loadmClient'

const route = useRoute()
const state = reactive({
  showRouter: true,
  ignorePath: ['/engineering/h5', '/'],
})

const reload = () => {
  state.showRouter = false
  nextTick(() => {
    state.showRouter = true
  })
}
provide('reload', reload)
watch(route, () => {
  if (route.path === '/login') {
    localforage.clear().then(function () {
      console.log('Database is now empty.')
    }).catch(function (err) {
      console.log(err)
    })
  }
}, {
  immediate: true
})
onMounted(() => { })
</script>

<style lang="scss" scope>
.scrollbar-enabled {
  // min-width: 1920px;
  overflow-x: auto;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #c0c0c0;
  ;
}
</style>
