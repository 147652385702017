import TenantsService from '@/service/tenants'
import { ElMessage } from 'element-plus'

import { useRouter } from 'vue-router'
const router = useRouter()

export const GetTenantInfo = async (res,type) => {
    const tenantAll = await TenantsService.getInstance().getTenantAllInfo()
    localStorage.setItem('tenant_All', JSON.stringify(tenantAll.result?.list))
    if(type=='check'){
      localStorage.setItem('tenant_alias_name', res.tenant_name || '')
    }else{
      let tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'))
      if(tenantInfo?.app_id){
        let index = tenantAll.result?.list.findIndex(item => item.app_id == tenantInfo?.app_id)

        if (index == -1) return

        if(tenantAll.result?.list[index].app_id != 0) {
          res.check_switch=1
          localStorage.setItem('tenant_alias_name', tenantAll.result?.list[index].name || '')
        }else{
          localStorage.setItem('tenant_alias_name', res.tenant_name || '')
        
        }
        localStorage.setItem('app_id', tenantAll.result?.list[index].app_id)
      }else{
        let currentIndex = tenantAll.result?.list.findIndex(item => item.current == true)
        if (currentIndex == -1) return
        localStorage.setItem('app_id', tenantAll.result?.list[currentIndex].app_id)
      }
    }
    
}