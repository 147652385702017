import service from '@/https/index'

//登录接口
export const loginAPI = data => {
	return service.post('/account/api/user/login', data)
}
//退出登录
export const useLogoutApi = () => {
	return service.post('/account/api/user/logout')
}
//微信扫码绑定手机号 --获取用户信息
export const bindAPI = data => {
	return service.post('/account/svr/api/user/wechat/bind', data)
}

//获取验证码
export const passwordCodePhoneAPI = data => {
	return service.get(`/account/svr/api/user/forget/password/code?phone=${data.phone}`)
}

//忘记密码--下一步--重置密码
export const codeCheckAPI = data => {
	return service.post('/account/svr/api/user/forget/password/code/check', data)
}


//忘记密码--下一步----重置密码--提交密码
export const forgetPasswordAPI = data => {
	return service.post('/account/svr/api/user/forget/password', data)
}

//微信扫码判断用户是否存在登录
export const wechatLoginAPI = data => {
	return service.post('/account/svr/api/user/wechat/login', data)
}
//获取菜单
export const getMenu = () => {
	return service.get(`/account/api/menu/getMenuByUser`)
}


export const updatePasswordApi = (data) => {
	return service.post('/account/api/user/change/password', data)
}

// 获取当前用户所在的租户信息
export const getTenant = () => {
	return service.get(`/api/user/tenant`)
}
// 切换租户用户
export const PutTenant = (id) => {
	return service.put(`/account/api/user/tenant/${id}`)
}

// 更新用户普通信息
export const updateUserInfo = (data) => {
	return service.post('/account/api/user/updateUserInfo', data)
}
// 通过id 获取用户信息
export const getUserInfoById = (data) => {
	return service.post('/account/svr/api/user/info/by/id', data)
}

//获取附件
export const createAttachment = (data) => {
	return service.post(`/account/api/attachment/createAttachment`, data)
}

//获取附件
export const getAttachment = (data) => {
	return service.get(`/account/api/attachment/getAttachment?attachmentType=${data.attachmentType}&userAccount=${data.userAccount}`)
}
// 通过uuid获取附件
export const getAttachmentByUuid = (data) => {
	return service.get(`/account/api/attachment/getAttachment?uuid=${data.uuid}`)
}

// 获取头像附件
export const getAvatar = (data) => {
	return service.get(`/account/api/attachment/getAvatar?attachmentType=${data.attachmentType}&userAccount=${data.userAccount}`)
}
// 通过uuid获取头像附件
export const getAvatarByUuid = (data) => {
	return service.get(`/account/api/attachment/getAvatar?uuid=${data.uuid}`)
}

// 保存头像附件
export const createAvatar = (data) => {
	return service.post(`/account/api/attachment/createAvatar`,data)
}

// 获取当前浏览的菜单列表
export const quickMenuAPI = (data) => {
	return service.get(`/account/api/menu/quickMenu`,data)
}

// 获取当前浏览的菜单列表
export const deleteQuickMenuAPI = (data) => {
	return service.post(`/account/api/menu/deleteQuickMenu`,data)
}
//手动创建菜单列表
export const createQuickMenuAPI = (data) => {
	return service.post(`/account/api/menu/createQuickMenu`,data)
}

//手动创建菜单列表
export const updateUserInfoAPI = (data) => {
	return service.post(`/account/api/user/updateUserInfo`,data)
}

// 通知消息已读
export const readBoolean = (data) => {
	return service.post('/notification/api/v1/notification/read', data)
 }

 export const menuClickAPI = data => {
	return service.post('/account/api/menu/click', data)
}


// 获取用户私章
export const getPrivateSignet = (data) => {
	return service.get(`/sign/signet/private`,data)
}

//通过token获取当前用户
export const getUserInfo = () => {
	return service.get('/account/api/user/info')
}